@use '../config/' as *;
.waf-ranking-hero {
    @extend %mb-6;
    .card-banner {
        // height: 51rem;
        height: 50rem;
        padding-block: 0 var(--space-14);
    }
    .card-wrap {
        &::before {
            content: "";
            width: 112.8rem;
            height: 112.8rem;
            pointer-events: none;
            opacity: 0.4;
            @include position(-75%, null, null, 20%);
            @include bgImg("svg/vector-gradient.svg", top 0 right 0, cover);
        }
    }
    .head-tab {
        @extend %mt-4;
        a::after {
            @include icon(arrow-right);
        }
    }
    .title {
        @extend %mb-4;
        @extend %neutral-5;
    }
    .desc {
        @extend %neutral-0;
        @extend %body-l;
    }
    .sub-event-wrap {
        @extend %d-none;
    }
}
@include mq(col-tablet) {
    .waf-ranking-hero {
        padding-bottom: var(--space-6);
        margin-bottom: 9.6rem;
        .card-banner {
            height: 60rem;
            aspect-ratio: 21/9;
            padding-block: 0 var(--space-6);
        }
        .card {
            &-wrap {
                padding-bottom: var(--space-16);
                &::before {
                    content: unset;
                }
            }
        }
        .head-tab {
            margin-top: var(--space-6);
        }
        .title {
            margin-bottom: var(--space-6);
        }
    }
}
@forward '../scss/main.scss';
@forward '../scss/standings/global-ranking';
@forward './module/common-hero';
@forward '../scss/module/ranking-banner';
@use './config/' as *;
.load-bg-pattern {
    @each $ranking-name,
    $ranking-img in $world-ranking {
        .waf-#{$ranking-name} {
            .layout-wrapper {
                @include bgImg("cssimages/world-ranking/#{$ranking-img}.png", center, cover);
            }
        }
    }
}